import Lottie from 'react-lottie-player'

const NetworkCard = ({ title, description, smallDesc, animationData, isImg, width, height }) => {
  return (
    <div className="network-card">
      <div className='network-animation'>
        {
          isImg ? <img src={animationData} style={{ width, height, margin: "20px" }} alt='' /> : <Lottie loop={true} animationData={animationData} play style={{ width, height }} />
        }
        </div>
      <h2>{title}</h2>
      <p className='network-desktop'>{description}</p>
      <p className='network-mobile'>{smallDesc}</p>
    </div>
  );
}

export default NetworkCard;