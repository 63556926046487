import React from "react";
import "./Problem.css";
import Prob0 from "../../assets/img/problem0.png";
const Problem = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner container-width"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-text">
        <h1>
          Meeting People is Hard
        </h1>
        <p className="meeting-people-desktop">
          Blue Social is redefining social networking by seamlessly connecting <br/> the online and offline worlds, fostering a more engaging and <br /> connected community.
        </p>

        <p className="meeting-people-mobile">
          Blue Social is redefining social networking by seamlessly connecting the online and offline worlds, fostering a more engaging and connected community.
        </p>

      </div>
      <div className="banner-img">
        <img className="blue-image-1" src={Prob0} alt="" />
      </div>
    </div>
  );
};

export default Problem;
