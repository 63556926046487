import NetworkCard from "./NetworkCard"
import "./style.css"
import animation1 from "./animations/animation-1.json"
import animation2 from "./animations/animation-2.json"
import animation3 from "./animations/animation-3.json"
import animation4 from "./animations/animation-4.json"
import animation5 from "./animations/animation-5.json"
import animation6 from "./animations/animation-6.json"
import bluetooth  from "./animations/bluetooth.png"



const NetworkSection = () => {


    return (
        <div  className="container-width feature">

            <button data-aos="fade-up" data-aos-duration="1000" className="gradient-btn f-banner-btn">The Future of Networking is HERE</button>

            <div className="network-container"  data-aos="fade-up" data-aos-duration="1000">

                <div className="network-row">

                    <NetworkCard 
                        title={<> See the people right <br /> around you. </>} 
                        description={
                            <>
                                Blue Social is a tool (mobile app) that lets <br/>
                                you instantly connect with the people who <br/> 
                                you see right around you! — in your field <br /> 
                                of view!
                            </>   
                        }
                        smallDesc={
                            <>
                                Blue Social is a tool (mobile app) that lets 
                                you instantly connect with the people who 
                                you see right around you! — in your field 
                                of view!
                            </>
                        }
                        width={328} height={216}
                        animationData={animation1} />

                    <NetworkCard 
                        title={<> Never miss the chance <br /> to connect. </>} 
                        description={
                            <>
                                Feeling shy? Let Blue Social help you break <br /> 
                                the ice! Send instant ice-breaker <br /> 
                                messages to people nearby so you can <br /> 
                                start meaningful conversations.
                            </>   
                        }
                        smallDesc={
                            <>
                                Feeling shy? Let Blue Social help you break
                                the ice! Send instant ice-breaker 
                                messages to people nearby so you can
                                start meaningful conversations.
                            </>
                        }
                        width={274} height={244}
                        animationData={animation2} />

                </div>

                <div className="network-row">

                    <NetworkCard 
                        title={<> Get rewarded for <br /> meeting new people. </>} 
                        description={
                            <>
                                Earn crypto tokens for every meaningful interaction. <br /> 
                                Build your network and turn <br /> 
                                your connections into rewards through our <br /> 
                                Socialize-to-Earn™ model.
                            </>   
                        }
                        smallDesc={
                            <>
                                Earn crypto tokens for every meaningful interaction. 
                                Build your network and turn 
                                your connections into rewards through our 
                                Socialize-to-Earn™ model.
                            </>
                        }
                        width={228} height={264}
                        animationData={animation3} />

                    <NetworkCard 
                        title={<>  Your contact info <br /> in one place. </>} 
                        description={
                            <>
                                Easily share your contact details, social <br /> 
                                media handles, and more. With Blue Social, <br /> 
                                everything is in one place, so you can <br /> 
                                make networking effortless and efficient.
                            </>   
                        }
                        smallDesc={
                            <>
                                Easily share your contact details, social 
                                media handles, and more. With Blue Social, 
                                everything is in one place, so you can 
                                make networking effortless and efficient.
                            </>
                        }
                        width={230} height={230}
                        animationData={animation4} />

                </div>

                <div className="network-row">

                    <NetworkCard 
                        title={<> Your connections <br /> across a map. </>} 
                        description={
                            <>
                                Easily view a map of all your social <br /> 
                                interactions and see exactly where you met <br /> 
                                each person. Whether you crossed paths <br /> 
                                at a conference or a local café, Blue Social <br /> 
                                helps you visualize your growing network <br /> 
                                in real-world locations.
                            </>   
                        }
                        smallDesc={
                            <>
                                Easily view a map of all your social 
                                interactions and see exactly where you met 
                                each person. Whether you crossed paths 
                                at a conference or a local café, Blue Social 
                                helps you visualize your growing network 
                                in real-world locations.
                            </>
                        }
                        width={290} height={192}
                        animationData={animation5} />

                    <NetworkCard 
                        title={<> The power of <br /> Bluetooth technology. </>} 
                        description={
                            <>
                                Blue Social uses Bluetooth to let you <br />
                                instantly connect and transfer data to <br />
                                nearby devices. Our proprietary <br />
                                technology works offline with no internet <br />
                                or Wi-Fi required!
                            </>   
                        }
                        smallDesc={
                            <>
                                Blue Social uses Bluetooth to let you 
                                instantly connect and transfer data to 
                                nearby devices. Our proprietary 
                                technology works offline with no internet 
                                or Wi-Fi required!
                            </>
                        }
                        width={276} height={"auto"}
                        imageMargin={"10px"}
                        animationData={bluetooth}
                        isImg={true}/>

                </div>

            </div>
        
        </div>
    )
}


export default NetworkSection