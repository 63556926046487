import React from "react";
import "./Problem.css";
//import Prob1 from "../../assets/img/problem1.png";
import BannerImg from "../../assets/img/HeroGif.gif";
const LetBlue = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner container-width change-orientation"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-img-2">
        <img className="blue-image-2" src={BannerImg} alt="" />
      </div>
      <div className="banner-text">
        <h1>
          Let Blue Introduce You®
        </h1>
        <p>
          Meeting new people has never been more fun! With our app, you earn BLUE tokens just by having real-life conversations. Using our Proof-of-Interaction™ (POI) protocol, we verify when you're nearby and genuinely engaging with others. The more you connect in person, the more you earn [Socialize-to-Earn™]!
        </p>
        <div className="btn-container">
          <a href="https://web3.blue.social/early-access" target="_blank" onClick={scrollToSection} className="blue-btn">
            Download Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default LetBlue;
