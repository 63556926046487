import React from "react";
import "./Banner.css";
import BannerImg from "../../assets/img/HeroGif.gif";
import winnerBadge from "../../assets/img/winner-badge.png"
import BlueSocialConnect from "../../assets/img/Blue-Social-Connect.png"
import googleImg from "../../assets/img/new/google.png"
import appleImg from "../../assets/img/new/apple.png"


const Banner = ({ buyTokenRef }) => {
  const scrollToSection = () => {
    const section = document.getElementById("buy-tokens");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div
        className="main-banner container-width"
        data-aos="fade-up"
        data-aos-duration="1000">
          
        <div className="banner-text" style={{display: "block", flexBasis: "500px"}}>

          <div className="hero-left">

            <div className="banner-logo-container">
              <img className="logo" width={"116px"} height={"116px"} src="/blue-logo.png" alt="blue" />
            </div>

            <h1 className="banner-header">It Pays to Be Social™</h1>

            <p className="network-desktop">
          
              Discover people around you like never before – Blue Social <br /> makes real-life connections fun, engaging, and rewarding.

              <br />
              <br />
              
              It’s like Pokémon GO, but for meeting new friends and <br /> building your network in the real world.

            </p>


            <p className="network-mobile">
          
              Discover people around you like never before – Blue Social makes real-life connections fun, engaging, and rewarding.

              <br />

              It’s like Pokémon GO, but for meeting new friends and building your network in the real world.

            </p>
            {/* <div className="btn-container">
              <a href="#buy-token"  onClick={scrollToSection} className="blue-btn">
                Buy BLUE Token
              </a>
              <a
                href="https://whitepaper.blue.social"
                target="_blank"
                className="white-paper-btn">
                Whitepaper
              </a>
            </div> */}

            <div className="hero-platforms">

              <a target="_blank" href="https://apps.apple.com/us/app/blue-social-networking-irl/id1151689697">
                <img src={appleImg} width={"165px"} height={"auto"} alt="apple" />
              </a>

              <a href="#">
                <img src={googleImg} width={"176px"} height={"auto"} alt="google" />
              </a>

            </div>

            {/* <a href="https://base.mirror.xyz/p_A3ZxaBUEcpWwTf9_Yg-jnlOro8m8Ypc8LEDnA_ZRk" target="_blank" className="we-won">
              <div><img height={42} width={28} src={winnerBadge} /></div>
              <p>GAMING with thirdweb: 🏆 Base Buildathon 2024 Winner </p>
            </a>  */}
          </div>

        </div>

        {/* <div className="header-banner-img">
          <img src={BannerImg} alt="" />
        </div> */}

      </div>

      <div className="hero-bg-img"> </div>

    </>
  );
};

export default Banner;
